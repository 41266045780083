import { useEffect, useState } from 'react';
import { AppDispatch } from '@store/store';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import SpinnerComponent from '@components/spinner/Fallback-spinner';
import { getAllChildren } from '@src/views/children/store';
import ChildrenState from '@src/views/children/types/ChildrenState';
import UserState from '@src/views/users/types/UserState';
import { Link } from 'react-router-dom';
import { getAllUsers } from '@src/views/users/store';
import Child from '@src/views/children/types/Child';
import LatestAnnouncements from '@src/views/announcements/components/LatestAnnouncements';
import {
  Card, CardBody, CardHeader, CardTitle,
  Col,
  Row,
} from 'reactstrap';
import DataTable from 'react-data-table-component';
import NoTableData from '@src/components/wrappers/NoTableData';
import TenantObjectState from '@src/views/tenantObjects/types/TenantObjectState';
import { getAllTenantObjectsAdministration } from '@src/views/tenantObjects/store';
import { isViewPermissionActive } from '@src/utility/context/ActivePermissions';
import {
  getTenantInfo, getInvoiceOptions,
} from '../../tenants/store';
import TenantFormHeader from '../../tenants/components/TenantFormHeader';

type ChildWithDebt = {
  child: Child,
  debt: number
}
function General() {
  const { t } = useTranslation();
  const homePermissions = JSON.parse(localStorage.getItem('userData') ?? '{}').permissions;
  const dispatch = useDispatch<AppDispatch>();
  const store = useSelector((state: any) => state.tenants);
  const childrenStore = useSelector((state: ChildrenState) => state.children);
  const [loading, setLoading] = useState(true);
  const AllChildren = useSelector((state: ChildrenState) => state.children.allChildren);
  const AllUsers = useSelector((state: UserState) => state.users.allUsers);
  const AllTenantObjects = useSelector(
    (state: TenantObjectState) => state.tenantObjects.allTenantObjects,
  );
  const [childrenWithDebt, setChildrenWithDebt] = useState<ChildWithDebt[]>([]);

  useEffect(() => {
    dispatch(getTenantInfo());
    dispatch(getInvoiceOptions());
    dispatch(getAllChildren('childStatus=Aktivan'));
    dispatch(getAllUsers());
    dispatch(getAllChildren());
    dispatch(getAllTenantObjectsAdministration());
    setLoading(false);
  }, []);

  useEffect(() => {
    if (childrenStore.allChildren.length) {
      const childrenWithFinance = AllChildren.map(
        (child) => {
          const invoiced = child.invoices.reduce(
            // eslint-disable-next-line no-return-assign
            (acc, invoice) => acc += Number(invoice.totalAmount), 0,
          );
          const paid = child.payments.reduce(
            // eslint-disable-next-line no-return-assign
            (acc, payment) => acc += Number(payment.amount), 0,
          );
          return { child, debt: paid - invoiced };
        },
      );
      const newChildrenWithDebt = childrenWithFinance.filter((child) => child.debt < 0);
      setChildrenWithDebt(newChildrenWithDebt);
    }
  }, [childrenStore.allChildren]);

  if (loading) {
    return <SpinnerComponent />;
  }

  return (
    <>
      <TenantFormHeader
        subtitle={`${t('General tenant information')}`}
        title={`${t('Welcome back to')} ${store.currentTenant?.name}!`}
        maxNumberOfChildren={store.currentTenant?.maxNumberOfChildProfiles}
        activeChildren={AllChildren.length}
        maxNumberOfUsers={store.currentTenant?.maxNumberOfUsers}
        activeUsers={AllUsers.length}
        maxNumberOfObjects={store.currentTenant?.maxNumberOfObjects}
        activeObjects={AllTenantObjects.length}
      />
      {
        isViewPermissionActive(homePermissions, 'Announcements')
          ? (
            <LatestAnnouncements />
          ) : <></>
      }
      <Row>
        <Col md={6}>
          <Card>
            <CardHeader className="d-flex">
              <div>
                <CardTitle tag="h4">{t('Debts')}</CardTitle>
              </div>
            </CardHeader>
            <CardBody>
              <DataTable
                noHeader
                className="react-dataTable"
                columns={[
                  {
                    name: t('Child'),
                    minWidth: '60px',
                    cell: (row: ChildWithDebt) => <Link to={`/children/${row.child.id}`}>{`${row.child.name} ${row.child.surname}`}</Link>,
                  },
                  {
                    name: t('Contract holder'),
                    minWidth: '60px',
                    cell: (row: ChildWithDebt) => <span key={`contract-holder-${row.child.id}`}>{`${row.child.contractHolder.name} ${row.child.contractHolder.surname}`}</span>,
                  },
                  {
                    name: t('Debt'),
                    minWidth: '60px',
                    cell: (row: ChildWithDebt) => <span key={`debt-${row.child.id}`}>{`${-row.debt} rsd`}</span>,
                  },
                ]}
                noDataComponent={<NoTableData searched={!loading} />}
                progressComponent={<div />}
                data={childrenWithDebt}
              />
            </CardBody>
          </Card>
        </Col>
        <Col md={6} />
      </Row>
    </>
  );
}

export default General;
