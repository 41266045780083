import FileUploader from '@src/components/file-uploader/FileUploader';
import FileType from '@src/components/file-uploader/types/FileType';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Col,
  Form,
  Row,
} from 'reactstrap';
import CustomLabel from '@src/components/forms/CustomLabel';
import RequestStatus from '@src/types/RequestStatus';
import { successToast } from '@src/components/wrappers/ToastMessages';
import { closeModal } from '@store/modal';
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '@store/store';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { addPaymentsViaExcel, getAllPayments } from '../store';

type PaymentsViaExcelFormData = {
  file: File,
}
const PaymentsViaExcel = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [files, setFiles] = useState<FileType[]>([]);
  const [newFiles, setNewFiles] = useState<File[]>([]);

  const { t } = useTranslation();

  const {
    control,
    handleSubmit,
    trigger,
    formState: { errors },
  } = useForm<PaymentsViaExcelFormData>({
    resolver: yupResolver(yup.object().shape({ file: yup.mixed().test('required', t('File is required'), () => files.length === 1) })),
  });

  const submitHandler = (data: PaymentsViaExcelFormData) => {
    data.file = newFiles?.[0];
    const formData = new FormData();
    if (data.file) formData.append('file', data.file);

    dispatch(addPaymentsViaExcel(formData)).then((res) => {
      if (res.meta.requestStatus === RequestStatus.REQUEST_FULFILLED) {
        successToast(t('Payments successfully added'));
        dispatch(getAllPayments());
        dispatch(closeModal());
      }
    });
  };

  useEffect(() => {
    if (newFiles?.length) {
      trigger(['file']);
    }
  }, [newFiles]);

  const acceptFiles: {
    [key: string]: string[];
  } = useMemo(() => ({
    'application/vnd.ms-excel': ['.xls'],
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
  }), []);

  return (
    <Form onSubmit={handleSubmit(submitHandler)}>
      <Row>
        <Col md={12}>
          <CustomLabel name={t('File')} required />
          <Controller
            name="file"
            control={control}
            render={() => (
              <FileUploader
                newFiles={newFiles}
                setNewFiles={setNewFiles}
                acceptFiles={acceptFiles}
                maxFiles={1}
                files={files}
                setFiles={setFiles}
              />
            )}
          />
          {errors.file
            && <p style={{ fontSize: '0.9rem' }} className="text-center text-danger">{errors.file.message}</p>}
        </Col>
        <Col xs={12} className="text-center mt-2 pt-50">
          <a
            href="https://go-kinder.fra1.digitaloceanspaces.com/tenants/formauplate.xlsx"
            download="UplateSablon.xlsx"
            className="btn btn-secondary"
          >
            {t('Download Template')}
          </a>
        </Col>
        <Col xs={12} className="text-center mt-2 pt-50">
          <Button
            type="submit"
            className="me-1"
            color="primary"
          >
            {t('Add')}
          </Button>
          <Button
            type="reset"
            color="secondary"
            outline
            onClick={() => dispatch(closeModal())}
          >
            {t('Close')}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default PaymentsViaExcel;
