import { useEffect, useRef, useState } from 'react';
import { AppDispatch } from '@store/store';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { isViewPermissionActive } from '@src/utility/context/ActivePermissions';
import TabContext from '@mui/lab/TabContext';
import { SyntheticEvent } from 'react-draft-wysiwyg';
import TabList from '@mui/lab/TabList';
import { Tab, Theme } from '@mui/material';
import TabPanel from '@mui/lab/TabPanel';
import {
  Button,
  Card,
  CardBody,
  Col,
  Input,
  Row,
  Spinner,
} from 'reactstrap';
import ConfirmationModalWrapper from '@src/components/wrappers/ConfirmationModalWrapper';
import { Trash } from 'react-feather';
import CustomFormFeedback from '@src/components/forms/CustomFormFeedback';
import { Controller, useForm } from 'react-hook-form';
import acceptedFiles from '@src/views/children/constants/accepted-files';
import { closeModal, openModal } from '@store/modal';
import { deleteUserImage, updateUserImage } from '@src/views/users/store';
import {
  getHumanResource,
} from '../store';
import HumanResourcesState from '../types/HumanResourceState';
import HumanResourceManagement from './HumanResourceManagement';
import HumanResourceFiles from '../components/HumanResourceFiles';
import HumanResourceDaysOff from '../components/HumanResourceDaysOff';
import PorfolioTab from '../components/PorfolioTab';
import HumanResourceDaysOffQuotaTab from '../components/HumanResourceDaysOffQuotaTab';

const HumanResourcesProfile = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const humanResourcesStore = useSelector((store: HumanResourcesState) => store.humanResources);
  const { humanResourceId } = useParams();
  const [loading, setLoading] = useState(true);
  const [imageSource, setImageSource] = useState('');
  const imageRef = useRef<HTMLInputElement>(null);
  const humanResourcePermissions = JSON.parse(localStorage.getItem('userData') ?? '{}').permissions;
  const [searchParams, setSearchParams] = useSearchParams();
  const activeTab = searchParams.get('tab');
  const navigate = useNavigate();
  const {
    control, setError, clearErrors, formState: { errors },
  } = useForm<{ image?:File, imageInput?: string }>();

  const handleTabsChange = (event: SyntheticEvent, newValue: string) => {
    setSearchParams({ tab: newValue });
  };

  useEffect(() => {
    if (humanResourceId) {
      dispatch(getHumanResource(+humanResourceId)).then((data) => {
        if (data.meta.requestStatus === 'rejected') {
          navigate('/404');
        } else {
          setLoading(false);
        }
      });
    }

    if (!activeTab) setSearchParams({ tab: 'staff-information' });
  }, []);

  useEffect(() => {
    if (humanResourcesStore.currentHumanResource.image) {
      setImageSource(humanResourcesStore.currentHumanResource.image);
    }
  }, [humanResourcesStore.currentHumanResource.image]);

  const onImageChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0] || null;
    if (file) {
      if (acceptedFiles.includes(file?.type ?? '')) {
        clearErrors('image');
        const reader = new FileReader();
        reader.onload = () => {
          const dataUrl = reader.result as string;
          setImageSource(dataUrl);
        };

        reader.readAsDataURL(file);

        if (humanResourceId) {
          dispatch(updateUserImage({ id: +humanResourceId, image: file }));
        }
      } else {
        setError('image', { type: 'value', message: t('Uploaded file is in unsuported type. Please upload .jpg, .jpeg or .png file') });
      }
    } else {
      clearErrors('image');
    }
  };

  const clearImage = () => {
    setImageSource('');
    clearErrors('image');
  };

  const imageClearHandler = () => {
    if (!humanResourceId) {
      clearImage();
    }

    if (humanResourceId && imageSource) {
      dispatch(
        openModal({
          Component: ConfirmationModalWrapper,
          title: t('Are you sure?'),
          subtitle: t('Image will be deleted'),
          open: true,
          componentProps: {
            handleConfirm: async () => {
              setLoading(true);
              try {
                await dispatch(deleteUserImage(+humanResourceId));
                clearImage();
                await dispatch(getHumanResource(+humanResourceId));
              } finally {
                setLoading(false);
                dispatch(closeModal());
              }
            },
          },
        }),
      );
    }
  };

  if (loading) {
    return (
      <Spinner
        size="lg"
        className="mx-auto my-4 text-center d-block"
        type="border"
        color="primary"
        style={{ width: '4rem', height: '4rem' }}
      />
    );
  }

  return (
    <TabContext value={activeTab ?? 'staff-information'}>
      <TabList
        variant="scrollable"
        scrollButtons={false}
        onChange={handleTabsChange}
        TabIndicatorProps={{
          style: {
            backgroundColor: '#7DCBEC',
          },
        }}
        sx={{ borderBottom: (theme: Theme) => `1px solid ${theme.palette.divider}`, '& .MuiTab-root': { py: 3.5 } }}
      >
        <Tab className="tenant_navigation" value="staff-information" label={t('Basic information')} />
        {
          isViewPermissionActive(humanResourcePermissions, 'humanResourcesFiles')
            ? (
              <Tab className="tenant_navigation" value="staff-files" label={t('Files')} />
            ) : <></>
        }
        {
          isViewPermissionActive(humanResourcePermissions, 'daysOffQuota')
            ? (
              <Tab className="tenant_navigation" value="staff-days-off-administration" label={t('Days off administration')} />
            ) : <></>
        }
        {
          isViewPermissionActive(humanResourcePermissions, 'daysOffQuota')
            ? (
              <Tab className="tenant_navigation" value="staff-days-off-quota" label={t('Days off quota')} />
            ) : <></>
        }
        {
          isViewPermissionActive(humanResourcePermissions, 'portfolioProfessionalDevelopment')
            ? (
              <Tab className="tenant_navigation" value="staff-porfolio" label={t('Portfolio')} />
            ) : <></>
        }
      </TabList>
      <TabPanel value="staff-information" className="px-0 mx-0">
        <Row>
          <Col md={3}>
            <Card>
              <CardBody>
                <Col
                  md={12}
                  className="d-flex"
                  style={{
                    justifyContent: 'center',
                    flexShrink: 0,
                    minWidth: '160px',
                    boxSizing: 'border-box',
                    alignItems: 'center',
                    flexDirection: 'column',
                    gap: '10px',
                  }}
                >
                  <Row className="d-none">
                    <Controller
                      name="imageInput"
                      control={control}
                      render={({ field }) => (
                        <Input
                          id="image"
                          type="file"
                          invalid={!!errors.image}
                          {...field}
                          innerRef={imageRef}
                          onChange={onImageChangeHandler}
                          accept=".png, .jpg, .jpeg"
                        />
                      )}
                    />
                  </Row>
                  <Row style={{ cursor: 'pointer', minWidth: '160px', minHeight: '160px' }} onClick={() => { imageRef.current?.click(); }}>
                    <img
                      src={imageSource || humanResourcesStore.currentHumanResource.image}
                      alt={t('Child')}
                      style={{
                        borderRadius: '5px', padding: 0, width: '160px', height: '160px',
                      }}
                    />
                  </Row>
                  <Row>
                    <Button
                      size="sm"
                      color="transparent"
                      className="btn btn-icon"
                      onClick={imageClearHandler}
                    >
                      <Trash className="text-center" size={15} />
                    </Button>
                  </Row>
                  {errors?.image
                    && (
                      <Row>
                        <CustomFormFeedback message={errors?.image?.message} />
                      </Row>
                    )}
                  <Row>
                    <h3 className="text-center mb-0">{`${humanResourcesStore.currentHumanResource.name} ${humanResourcesStore.currentHumanResource.surname}`}</h3>
                  </Row>
                </Col>
              </CardBody>
            </Card>
          </Col>
          <Col md={9}>
            <HumanResourceManagement
              humanResourcesData={humanResourcesStore.currentHumanResource.humanResourceData ?? {
                userId: humanResourcesStore.currentHumanResource.id,
                note: '',
                education: '',
                personalEmail: humanResourcesStore.currentHumanResource.email,
                address: '',
                municipality: '',
                city: '',
                phoneNumber: humanResourcesStore.currentHumanResource.phoneNumber,
                personalId: '',
                dateOfBirth: new Date(),
                embg: '',
                workPosition: '',
                licensureExpirience: '',
                workExpirience: '',
                foreignLanguages: '',
                itLiteracy: '',
                name: `${humanResourcesStore.currentHumanResource.name} ${humanResourcesStore.currentHumanResource.surname}`,
              }}
            />
          </Col>
        </Row>
      </TabPanel>
      {
      isViewPermissionActive(humanResourcePermissions, 'humanResourcesFiles')
        ? (
          <TabPanel value="staff-files" className="px-0 mx-0">
            <HumanResourceFiles />
          </TabPanel>
        ) : <></>
      }
      {isViewPermissionActive(humanResourcePermissions, 'daysOffQuota')
        ? (
          <TabPanel value="staff-days-off-administration" className="px-0 mx-0">
            <HumanResourceDaysOff isAdministration />
          </TabPanel>
        ) : <></>}
      {isViewPermissionActive(humanResourcePermissions, 'daysOffQuota')
        ? (
          <TabPanel value="staff-days-off-quota" className="px-0 mx-0">
            <HumanResourceDaysOffQuotaTab />
          </TabPanel>

        ) : <></>}
      {isViewPermissionActive(humanResourcePermissions, 'portfolioProfessionalDevelopment')
        ? (
          <TabPanel value="staff-porfolio" className="px-0 mx-0">
            <PorfolioTab />
          </TabPanel>
        ) : <></>}
    </TabContext>
  );
};

export default HumanResourcesProfile;
