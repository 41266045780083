import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

const invoiceValidationSchema = (isEdit: boolean) => {
  const { t } = useTranslation();
  const validation = yup.object().shape({
    invoiceNumber: yup.string().required(t('Invoice number is required')),
    dateIssued: yup.date().required(t('Date issued is required')),
    servicePeriodFrom: yup.date().required(t('Service period from issued is required')),
    servicePeriodTo: yup.date().required(t('Service periodTo is required')),
    dueDate: yup.date().required(t('dueDate issued is required')),
    yearId: yup.number().required(t('Year is required')),
    invoiceType: yup.string().required(t('Invoice type is required')),
    paidStatus: isEdit ? yup.string().required('Paid Status is required') : yup.string(),
    model: yup.number().required(t('Model is required')),
    packages: yup.array().min(1, t('At least one package is required')).test('required', t('Please select valid package'), (pkgs: any) => pkgs.reduce((prev: boolean, pkg: any) => prev && !!pkg?.packageId, true)),
  });
  return validation;
};

const invoiceBulkAddSchema = () => {
  const { t } = useTranslation();
  const validation = yup.object().shape({
    invoiceNumber: yup.string().required(t('Invoice number is required')),
    yearId: yup.object().nullable().required(t('Year is required')).shape({
      value: yup.number().required(t('Year is required')),
    }),
    invoiceType: yup.object().nullable().required(t('Invoice type is required')).shape({
      value: yup.string().required(t('Invoice type is required')),
    }),
    month: yup.object().nullable().required(t('Month is required')).shape({
      value: yup.number().required(t('Month is required')),
    }),
  });
  return validation;
};

export { invoiceValidationSchema, invoiceBulkAddSchema };
