/* eslint-disable jsx-a11y/label-has-associated-control */
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

const PaymentSlip = (
  { paymentSlipData } : { paymentSlipData: any },
) => {
  const { t } = useTranslation();
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Col style={{ maxWidth: '208mm' }}>
        <Col
          style={{
            textAlign: 'end',
            fontWeight: 'bold',
            fontSize: '18px',
          }}
        >
          NALOG ZA UPLATU
        </Col>
        <Row>
          <Col>
            <Col>
              <label htmlFor="payer">{t('Payer').toLocaleLowerCase()}</label>
              <br />
              <div
                style={{
                  height: '15mm',
                  width: '100%',
                  resize: 'none',
                  border: '1px solid #767676',
                }}
                id="uplatilac"
              >
                {paymentSlipData.payer}
              </div>
            </Col>
            <Col>
              <label htmlFor="purposeOfPayment">{t('Purpose of payment').toLocaleLowerCase()}</label>
              <br />
              <div
                style={{
                  height: '15mm',
                  width: '100%',
                  resize: 'none',
                  border: '1px solid #767676',
                }}
                id="uplatilac"
              >
                {paymentSlipData.purposeOfPayment}
              </div>
            </Col>
            <Col>
              <label htmlFor="payee">{t('Payee').toLocaleLowerCase()}</label>
              <br />
              <div
                style={{
                  height: '15mm',
                  width: '100%',
                  resize: 'none',
                  border: '1px solid #767676',
                }}
                id="uplatilac"
              >
                {paymentSlipData.payee}
              </div>
            </Col>
          </Col>
          <Col md={1} style={{ borderLeft: 'solid 1px', padding: 0, maxWidth: '1px' }} />
          <Col>
            <Col>
              <Row style={{ justifyContent: 'space-between' }}>
                <Col md={2}>
                  <label htmlFor="sifra">sifra</label>
                  <br />
                  <div
                    style={{
                      height: '9mm',
                      width: '100%',
                      resize: 'none',
                      border: '1px solid #767676',
                      alignItems: 'center',
                      justifyContent: 'center',
                      textAlign: 'center',
                      display: 'flex',
                    }}
                    id="sifra"
                  >
                    189
                  </div>
                </Col>
                <Col md={2}>
                  <label htmlFor="valuta">valuta</label>
                  <br />
                  <div
                    style={{
                      height: '9mm',
                      width: '100%',
                      resize: 'none',
                      border: '1px solid #767676',
                      alignItems: 'center',
                      justifyContent: 'center',
                      textAlign: 'center',
                      display: 'flex',
                    }}
                    id="valuta"
                  >
                    RSD
                  </div>
                </Col>
                <Col md={8}>
                  <label htmlFor="uplatilac">iznos</label>
                  <br />
                  <div
                    style={{
                      height: '9mm',
                      width: '100%',
                      resize: 'none',
                      border: '1px solid #767676',
                      alignItems: 'center',
                      justifyContent: 'center',
                      textAlign: 'center',
                      display: 'flex',
                    }}
                    id="iznos"
                  >
                    {paymentSlipData.totalAmount}
                  </div>
                </Col>
              </Row>
            </Col>
            <Col>
              <label htmlFor="bankAccountNumber">{t('Bank account number').toLocaleLowerCase()}</label>
              <br />
              <div
                style={{
                  height: '9mm',
                  width: '100%',
                  resize: 'none',
                  border: '1px solid #767676',
                  alignItems: 'center',
                  justifyContent: 'center',
                  textAlign: 'center',
                  display: 'flex',
                }}
                id="bankAccountNumber"
              >
                {paymentSlipData.accountNumber}
              </div>
            </Col>
            <Col>
              <label htmlFor="model">model</label>&nbsp;i&nbsp;<label htmlFor="referenceNumber"> poziv na broj (odobrenje)</label>
              <Row style={{ justifyContent: 'space-between' }}>
                <Col md={2}>
                  <div
                    style={{
                      height: '9mm',
                      width: '100%',
                      resize: 'none',
                      border: '1px solid #767676',
                      alignItems: 'center',
                      justifyContent: 'center',
                      textAlign: 'center',
                      display: 'flex',
                    }}
                    id="model"
                  >
                    {paymentSlipData.model}
                  </div>
                </Col>
                <Col md={10}>
                  <div
                    style={{
                      height: '9mm',
                      width: '100%',
                      resize: 'none',
                      border: '1px solid #767676',
                      alignItems: 'center',
                      justifyContent: 'center',
                      textAlign: 'center',
                      display: 'flex',
                    }}
                    id="referenceNumber"
                  >
                    {paymentSlipData.referenceNumber}
                  </div>
                </Col>
              </Row>
            </Col>
          </Col>
        </Row>
        <Col>
          <Col md={5}>
            <div
              style={{
                height: '15mm',
                width: '100%',
                border: 'none',
                borderBottom: '1px solid',
              }}
              id="uplatilac"
            />
            <br />
            <label htmlFor="uplatilac">potpis i pecat primaoca</label>
          </Col>
          <Row>
            <Col md={5} />
            <Col md={3}>
              <div
                style={{
                  height: '2mm',
                  width: '100%',
                  border: 'none',
                  borderTop: '1px solid',
                }}
                id="uplatilac"
              />
              <br />
              <label htmlFor="uplatilac">mesto i datum prijema</label>
            </Col>
            <Col md={3}>
              <div
                style={{
                  height: '2mm',
                  width: '100%',
                  border: 'none',
                  borderTop: '1px solid',
                }}
                id="uplatilac"
              />
              <br />
              <label htmlFor="uplatilac">datum valute</label>
            </Col>
          </Row>
        </Col>
      </Col>
    </div>
  );
};

export default PaymentSlip;
