/* eslint-disable new-cap */
/* eslint-disable import/no-extraneous-dependencies */
import { useEffect, useRef, useState } from 'react';
import { AppDispatch } from '@store/store';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Spinner,
} from 'reactstrap';
import html2canvas from 'html2canvas';
import { ChevronLeft } from 'react-feather';
import jsPDF from 'jspdf';
import {
  getInvoiceByHash,
  getInvoiceById,
} from '../store';
import InvoiceState from '../types/InvoiceState';
import CreateInvoicePackage from '../types/CreateInvoicePackage';
import { formatDateLocalDateString } from '../components/InvoiceColumns';
import PaymentSlip from '../components/PaymentSlip';
import InvoiceItemTable from '../components/InvoiceItemTable';

type ShowData = {
  tenantLogo: string;
  tenantName: string;
  userName: string;
  userSurname: string;
  tenantAddress: string;
  tenantCity: string;
  tenantPhoneNumber: string;
  tenantEmail: string;
  tenantTaxId: number;
  tenantIdentificationNumber: string;
  childName: string;
  childSurname: string;
  childCity: string;
  childStreet: string;
  childPostalCode: number;
  childEMBG: string;
  tenantVat: string;
  id: number;
  dateIssued: string;
  servicePeriodFrom: string;
  servicePeriodTo: string;
  dueDate: string;
  totalAmount: string;
  invoiceNote?: string;
  payer: string;
  payee: string;
  purposeOfPayment: string;
  referenceNumber: string;
  invoiceNumber: string;
  model: number;
  childId: number;
  invoiceType: string,
  accountNumber: string,
  packages: CreateInvoicePackage[];
}

const InvoicePreview = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const invoiceStore = useSelector((store: InvoiceState) => store.invoice);
  const { invoiceId, invoiceHash } = useParams();
  const [loading, setLoading] = useState(true);
  const [isGenerating, setIsGenerating] = useState(false);
  const navigate = useNavigate();
  const pdfRef = useRef(null);
  const [showValues, setShowValues] = useState<ShowData>({
    tenantLogo: '',
    tenantName: '',
    userName: '',
    userSurname: '',
    tenantAddress: '',
    tenantCity: '',
    tenantPhoneNumber: '',
    tenantEmail: '',
    tenantTaxId: 0,
    tenantIdentificationNumber: '',
    childName: '',
    childSurname: '',
    childCity: '',
    childStreet: '',
    childPostalCode: 0,
    childEMBG: '',
    tenantVat: '0',
    id: 0,
    childId: 0,
    dateIssued: new Date().toISOString(),
    servicePeriodFrom: new Date().toISOString(),
    servicePeriodTo: new Date().toISOString(),
    dueDate: new Date().toISOString(),
    totalAmount: '',
    payer: '',
    payee: '',
    purposeOfPayment: '',
    referenceNumber: '',
    invoiceNumber: '',
    model: 0,
    invoiceType: '',
    accountNumber: '',
    packages: [],
  });

  useEffect(() => {
    if (invoiceHash) {
      dispatch(getInvoiceByHash(invoiceHash));
    } else if (invoiceId) {
      dispatch(getInvoiceById(+invoiceId));
    }
  }, []);

  useEffect(() => {
    if (invoiceStore.invoice.id) {
      setShowValues({
        tenantLogo: invoiceStore.invoice.tenant.logo,
        tenantName: invoiceStore.invoice.tenantName,
        userName: invoiceStore.invoice.userName,
        userSurname: invoiceStore.invoice.userSurname,
        tenantAddress: invoiceStore.invoice.tenantAddress,
        tenantCity: invoiceStore.invoice.tenantCity,
        tenantPhoneNumber: invoiceStore.invoice.tenantPhoneNumber,
        tenantEmail: invoiceStore.invoice.tenantEmail,
        tenantTaxId: invoiceStore.invoice.tenantTaxId,
        tenantIdentificationNumber: invoiceStore.invoice.tenantIdentificationNumber,
        childName: invoiceStore.invoice.childName,
        childSurname: invoiceStore.invoice.childSurname,
        childCity: invoiceStore.invoice.childCity,
        childStreet: invoiceStore.invoice.childStreet,
        childPostalCode: invoiceStore.invoice.childPostalCode,
        childEMBG: invoiceStore.invoice.childEmbg,
        tenantVat: invoiceStore.invoice.tenantVat,
        id: invoiceStore.invoice.id,
        invoiceNote: invoiceStore.invoice.note,
        payer: invoiceStore.invoice.payer,
        payee: invoiceStore.invoice.payee,
        purposeOfPayment: invoiceStore.invoice.purposeOfPayment,
        dueDate: invoiceStore.invoice.dueDate,
        dateIssued: invoiceStore.invoice.dateIssued,
        servicePeriodFrom: invoiceStore.invoice.servicePeriodFrom,
        servicePeriodTo: invoiceStore.invoice.servicePeriodTo,
        model: invoiceStore.invoice.model,
        referenceNumber: invoiceStore.invoice.referenceNumber,
        invoiceNumber: invoiceStore.invoice.invoiceNumber,
        invoiceType: invoiceStore.invoice.invoiceType,
        totalAmount: invoiceStore.invoice.totalAmount,
        accountNumber: invoiceStore.invoice.tenantAccountNumber,
        packages: invoiceStore.invoice.packages,
        childId: invoiceStore.invoice.childId,
      });
      setLoading(false);
    }
  }, [invoiceStore.invoice]);

  if (loading) {
    return (
      <Spinner
        size="lg"
        className="mx-auto my-4 text-center d-block"
        type="border"
        color="primary"
        style={{ width: '4rem', height: '4rem' }}
      />
    );
  }

  const handleDownloadPDF = async () => {
    setIsGenerating(true); // Disable button and show loading state

    try {
      const input = pdfRef.current;
      const canvas = await html2canvas(input as unknown as HTMLElement, {
        allowTaint: false,
        useCORS: true,
      });

      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'px', 'a4');

      const width = pdf.internal.pageSize.getWidth();
      const height = pdf.internal.pageSize.getHeight();
      const imgWidth = canvas.width;
      const imgHeight = canvas.height;

      const ratio = Math.min(width / imgWidth, height / imgHeight);
      pdf.addImage(imgData, 'PNG', 0, 0, width, imgHeight * ratio);
      pdf.save(`${invoiceStore.invoice.referenceNumber}.pdf`);
    } catch (error) {
      console.error('PDF generation failed:', error);
    } finally {
      setIsGenerating(false); // Re-enable button
    }
  };

  return (
    <div
      style={{
        display: 'flex', width: '100%', gap: '20px', justifyContent: 'center', flexWrap: 'wrap',
      }}
    >
      <Card>
        <CardHeader style={{ paddingBottom: '0' }}>
          <Button
            color="outline"
            outline
            style={{
              margin: 0, padding: 0, color: 'inherit', fontWeight: 'inherit',
            }}
            onClick={() => navigate(-1)}
          >
            <ChevronLeft size={21} />
          </Button>
        </CardHeader>
        <div ref={pdfRef}>
          <CardBody>
            <Row className="m-sm-4">
              <Col>
                <img width="50px" height="50px" src={showValues.tenantLogo} alt="Logo" />
                <span className="app-brand-text fs-3" style={{ fontWeight: 'bold', marginLeft: '8px' }}>{showValues.tenantName}</span>
              </Col>
            </Row>
            <Row className="m-sm-4">
              <Col md={7}>
                <Col>
                  <p>
                    <span style={{ fontWeight: 'bold' }}>{t(`${showValues.invoiceType}`)}: </span>
                    {showValues.referenceNumber}
                  </p>
                  <Col className="d-flex align-items-center md-align-items-end">
                    <p>
                      <span style={{ fontWeight: 'bold' }}>{t('Date Issued')}: </span>
                      {formatDateLocalDateString(showValues.dateIssued)}
                    </p>
                  </Col>
                  <Col className="d-flex align-items-center md-align-items-end">
                    <p>
                      <span style={{ fontWeight: 'bold' }}>{t('Service Period')}: </span>
                      {formatDateLocalDateString(showValues.servicePeriodFrom)}&nbsp;-
                      &nbsp;{formatDateLocalDateString(showValues.servicePeriodTo)}
                    </p>
                  </Col>
                  <p>
                    <span style={{ fontWeight: 'bold' }}>{t('Invoice issuer')}
                    </span>: {showValues.userName} {showValues.userSurname}
                  </p>
                  <h5 className="mb-2" style={{ fontWeight: 'bold' }}>{t('Recipient information')}:</h5>
                  <p className="mb-1"><span style={{ fontWeight: 'bold' }}>{t('Full name')}</span>: {showValues.childName} {showValues.childSurname}</p>
                  <p className="mb-1"><span style={{ fontWeight: 'bold' }}>{t('EMBG')}</span>: {showValues.childEMBG}</p>
                </Col>
              </Col>
              <Col md={5} className="d-flex align-items-end" style={{ flexDirection: 'column' }}>
                <Col>
                  <p className="mb-1"><span style={{ fontWeight: 'bold' }}>{t('Address')}</span>: {showValues.tenantAddress}, {showValues.tenantCity}</p>
                  <p className="mb-1"><span style={{ fontWeight: 'bold' }}>{t('Phone number')}</span>: {showValues.tenantPhoneNumber}</p>
                  <p className="mb-1"><span style={{ fontWeight: 'bold' }}>{t('Email')}</span>: {showValues.tenantEmail}</p>
                  <p className="mb-1"><span style={{ fontWeight: 'bold' }}>{t('TaxID')}</span>: {showValues.tenantTaxId}</p>
                  <p className="mb-1"><span style={{ fontWeight: 'bold' }}>{t('Identification number')}</span>: {showValues.tenantIdentificationNumber}</p>
                  <p className="mb-1"><span style={{ fontWeight: 'bold' }}>{t('Industry code')}</span>: 2254</p>
                  <p className="mb-1"><span style={{ fontWeight: 'bold' }}>{t('Account number')}</span>: {showValues.accountNumber}</p>
                </Col>
              </Col>
            </Row>
            <Col className="m-sm-4" style={{ padding: '0 14px' }}>
              <Col className="d-flex align-items-center md-align-items-end">
                <p>
                  <span style={{ fontWeight: 'bold' }}>{t('Note: The due date is')} </span>
                  <span style={{ fontWeight: 'bold' }}>{formatDateLocalDateString(showValues.dueDate)}</span>
                </p>
              </Col>
              <p style={{ textAlign: 'center' }}>
                {t('This document was created through automated data processing and printed using a computer, and as such, it is valid without a seal or signature')}.
              </p>
            </Col>
            <hr />
            <Col className="m-sm-4">
              <InvoiceItemTable
                packages={invoiceStore.invoice.packages}
                total={invoiceStore.invoice.totalAmount}
              />
              {invoiceStore.invoice.note && (
                <p style={{ marginTop: '1rem' }}><span style={{ fontWeight: 'bold' }}>{t('Remark')}: </span>{invoiceStore.invoice.note}</p>
              )}
            </Col>
            <hr />
            <Row className="m-sm-4">
              <PaymentSlip
                paymentSlipData={{
                  payer: invoiceStore.invoice.payer,
                  payee: invoiceStore.invoice.payee,
                  purposeOfPayment: invoiceStore.invoice.purposeOfPayment,
                  totalAmount: invoiceStore.invoice.totalAmount,
                  accountNumber: invoiceStore.invoice.tenantAccountNumber,
                  model: invoiceStore.invoice.model,
                  referenceNumber: invoiceStore.invoice.referenceNumber,
                }}
              />
            </Row>
          </CardBody>
        </div>
      </Card>
      <Card style={{ height: '100%' }}>
        <CardHeader>
          <div>
            <Button onClick={handleDownloadPDF} color="primary" block disabled={isGenerating}>
              {isGenerating ? t('Generating...') : t('Download PDF')}
            </Button>
          </div>
        </CardHeader>
      </Card>
    </div>
  );
};

export default InvoicePreview;
