import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

const showErrors = (field: string, valueLen: number, min: number) => {
  if (valueLen === 0) {
    return `${field} je obavezna`;
  }
  if (valueLen > 0 && valueLen < min) {
    return `${field} mora sadržati najmanje ${min} karaktera`;
  }
  return '';
};
const ResetPasswordValidationSchema = yup.object().shape({
  password: yup
    .string()
    .min(8, (obj) => showErrors('lozinka', obj.value.length, obj.min))
    .matches(
      /^((?=.*[a-z])(?=.*[A-Z]))(?=.*\d)(?=.*[!@#*$%^&+=.~-])(^.{8,}$)/,
      `Vaša nova lozinka mora ispunjavati kriterijume bezbednosti što podrzumeva najmanje: 8 karaktera,
      jedno veliko slovo, jedan broj i jedan specijalni karakter (sledeći karakteri su dozvoljeni: !@#*$%^&+=.~- )`,
    )
    .required(),
  confirmPassword: yup
    .string()
    .min(8, (obj) => showErrors('potvrda lozinke', obj.value.length, obj.min))
    .required()
    .oneOf([yup.ref('password'), null], 'Lozinke se moraju poklapati'),
});

const SendResetPasswordEmailValidationSchema = () => {
  const { t } = useTranslation();

  const validation = yup.object().shape({
    email: yup
      .string()
      .email(t('Enter email in valid format'))
      .required('Email is required'),
  });

  return validation;
};

const ChangePasswordValidationSchema = () => {
  const { t } = useTranslation();

  const validation = yup.object().shape({
    old_password: yup.string().required(t('Old password is required')),
    password: yup
      .string()
      .min(8, t('New password is required'))
      .matches(
        /^((?=.*[a-z])(?=.*[A-Z]))(?=.*\d)(?=.*[!@#*$%^&+=.~-])(^.{8,}$)/,
        t('Your new password must have at least: 8 characters, one uppercase letter, one number, and one special character (the following characters are allowed: !@#*$%^&+=.~-)'),
      )
      .required(),
    confirm_password: yup
      .string()
      .min(8, t('Password confirmation is required'))
      .required()
      .oneOf([yup.ref('password'), null], t('Passwords do not match')),
  });

  return validation;
};

export {
  ResetPasswordValidationSchema, ChangePasswordValidationSchema,
  SendResetPasswordEmailValidationSchema,
};
